import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Suspense, lazy, FunctionComponent } from "react";
import {
  getRole,
  hasAdHocRequests,
  hasInternalLearning,
  hasRequestByVirtualCard,
  hasResourceTypeView,
  hasVirtualCardsToggle,
  isPotsApprover,
  isOrgAdmin,
  hasExpenseToggle,
  hasRequestAnythingOption,
  isApprover,
} from "src/shared/util/user-constraints";

import { useAppContext } from "src/app-context";

import { logout } from "./shared/util/auth";
import Loading from "./shared/components/loading";
import withErrorBoundary from "./shared/components/error-boundary";
import MagicLink from "./magic-link";
import AuthCallback from "./auth-callback"; // needs to be non lazy
import AuthInit from "./auth-init"; // needs to be non lazy
import ResourceTypePage from "./resource-types/resource-type-page";
import { AuthenticatedRoute } from "./shared/components/AuthenticatedRoute/AuthenticatedRoute";
import {
  IsAuthorised,
  IsAuthorisedFeature,
  IsAuthorisedFeatureMix,
} from "./shared/components/IsAuthorised/IsAuthorised";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const Home = lazy(() => import("./home"));
const Search = lazy(() => import("./AlgoliaSearch"));
const YourRecommendations = lazy(() => import("./YourRecommendations"));
const JobFamilyRecommendations = lazy(
  () => import("./job-family-recommendations"),
);
const JobFamilyPageData = lazy(
  () => import("./job-family-page/components/job-families/data/"),
);
const JobFamilyPagePeopleHr = lazy(
  () => import("./job-family-page/components/job-families/people-hr/"),
);
const JobFamilyPageProduct = lazy(
  () => import("./job-family-page/components/job-families/product/"),
);
const JobFamilyPageSales = lazy(
  () => import("./job-family-page/components/job-families/sales/"),
);
const JobFamilyPageSoftwareEngineering = lazy(
  () =>
    import("./job-family-page/components/job-families/software-engineering/"),
);

const Invitation = lazy(() => import("./invitation"));
const Onboarding = lazy(() => import("./onboarding"));
const Bookmarks = lazy(() => import("./bookmarks"));
const Playlists = lazy(() => import("./playlists"));
const RecommendedPlaylists = lazy(() => import("./recommended-playlists"));
const OrganisationPlaylists = lazy(() => import("./organisation-playlists"));
const Playlist = lazy(() => import("./playlist"));
const NotFoundPage = lazy(() => import("./shared/components/not-found-page"));
const Me = lazy(() => import("./me"));
const YourPlaylists = lazy(() => import("./your-playlists"));
const EditPreferences = lazy(() => import("./me/components/edit-preferences"));
const Requests = lazy(() => import("./requests"));
const RequestDetails = lazy(() => import("./request-details"));
const Resource = lazy(() => import("./resource"));
const DownloadIcs = lazy(() => import("./download-ics"));
const ForgottenPassword = lazy(
  () => import("./shared/components/forgotten-password"),
);
const ResetPassword = lazy(() => import("./shared/components/reset-password"));
const ResetPasswordLegacy = lazy(
  () => import("./shared/components/reset-password-legacy"),
);
const Request = lazy(() => import("./request"));
const RequestAdHoc = lazy(() => import("./request-ad-hoc"));
const RequestConfirmedSummary = lazy(
  () => import("./request-confirmed-summary"),
);
const RequestExternal = lazy(() => import("./request-external"));
const RequestVirtualCard = lazy(() => import("./request-virtual-card"));
const RequestExpense = lazy(() => import("./request-expense"));
const GrowthPlan = lazy(() => import("./growth-plan"));
const SlackAuth = lazy(() => import("./slack-auth"));
const Supplier = lazy(() => import("./supplier"));
const Topic = lazy(() => import("./topic"));
const TopicPlaylists = lazy(() => import("./topic-playlists"));
const TrendingTopic = lazy(() => import("./trending-topic"));
const TopicSuppliers = lazy(() => import("./topic-suppliers"));
const Subtopic = lazy(() => import("./subtopic"));
const Explore = lazy(() => import("./explore"));
const Team = lazy(() => import("src/team"));
const Admin = lazy(() => import("src/admin"));
const LoginPage = lazy(() => import("src/login-page"));
const InternalResource = lazy(() => import("./internal-resource"));
const InternalResources = lazy(() => import("src/internal-resources"));
const Create = lazy(() => import("./create"));
const Approve = lazy(() => import("./approve"));
const Skill = lazy(() => import("./skill"));
const LearningPathway = lazy(() => import("./learning-pathway/pathway"));
const LearningPathways = lazy(() => import("./learning-pathway"));
const CreateLearningPathway = lazy(() => import("./learning-pathway/create"));
const LabsROIPage = lazy(() => import("./labs/roi"));

const Logout = () => {
  logout();
  return null;
};

export const Routes: FunctionComponent = () => {
  const { currentUser, orgPageUrl } = useAppContext();
  const { pathname, search } = useLocation();

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route
          path="/:url*"
          exact
          strict
          render={() => <Redirect to={`${pathname}/${search}`} />}
        />

        <AuthenticatedRoute exact path="/">
          <Home />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/admin/team">
          <IsAuthorised anyOf={[isOrgAdmin, isApprover]}>
            <Team />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path={`/${orgPageUrl}/team`}>
          <IsAuthorised anyOf={[isApprover]}>
            <Team />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/admin">
          <IsAuthorised anyOf={[isOrgAdmin]}>
            <Admin />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path={`/${orgPageUrl}`}>
          <IsAuthorised anyOf={[isOrgAdmin]}>
            <Admin />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path={`/create/`}>
          <IsAuthorised anyOf={[isOrgAdmin, isPotsApprover]}>
            <Create role={getRole(currentUser)} />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path={`/approve/`}>
          <IsAuthorised anyOf={[isPotsApprover]}>
            <Approve />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/resources/:resourceId/ics/:eventId">
          <DownloadIcs />
        </AuthenticatedRoute>

        <AuthenticatedRoute path={`/resources/:id`}>
          <Resource />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/latest-playlists">
          <Playlists />
        </AuthenticatedRoute>

        <AuthenticatedRoute path={`/explore/${orgPageUrl}/playlists`}>
          <OrganisationPlaylists />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/playlists/:playlistId">
          <Playlist />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/recommended-playlists">
          <RecommendedPlaylists />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/approvals">
          <IsAuthorised anyOf={[isApprover]}>
            <Team />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/me/edit">
          <EditPreferences />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/me/playlists">
          <YourPlaylists />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/pathways/:pathwayId">
          <IsAuthorisedFeature feature="learning_pathways">
            <LearningPathway />
          </IsAuthorisedFeature>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/me/pathways/create/step/:step/">
          <IsAuthorisedFeature feature="learning_pathways">
            <CreateLearningPathway />
          </IsAuthorisedFeature>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/me/pathways/">
          <IsAuthorisedFeature feature="learning_pathways">
            <LearningPathways />
          </IsAuthorisedFeature>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/me">
          <Me />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/requests">
          <Requests />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/request/summary/:id">
          <RequestConfirmedSummary />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/request/off-platform/:resourceId?/:productId?">
          <IsAuthorised
            anyOf={[hasRequestByVirtualCard, hasVirtualCardsToggle]}
          >
            <RequestVirtualCard />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/request/external-expense">
          <IsAuthorised anyOf={[hasExpenseToggle]}>
            <RequestExpense />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/request/resource-not-on-learnerbly">
          <IsAuthorised anyOf={[hasRequestAnythingOption]}>
            <RequestExternal />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/request/:resourceId/:id">
          <Request />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/request/:requestId">
          <IsAuthorisedFeatureMix
            anyOfFeature={["self_service_resource_codes", "digital_resources"]}
            anyOf={[hasRequestByVirtualCard, hasVirtualCardsToggle]}
          >
            <RequestDetails />
          </IsAuthorisedFeatureMix>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/request">
          <IsAuthorised anyOf={[hasAdHocRequests]}>
            <RequestAdHoc />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path={"/search"}>
          <Search />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/bookmarks">
          <Bookmarks />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/recommended/job-family">
          <JobFamilyRecommendations />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/recommended">
          <YourRecommendations />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/role/data">
          <IsAuthorisedFeature feature="job_family_page">
            <JobFamilyPageData />
          </IsAuthorisedFeature>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/role/people">
          <IsAuthorisedFeature feature="job_family_page">
            <JobFamilyPagePeopleHr />
          </IsAuthorisedFeature>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/role/product">
          <IsAuthorisedFeature feature="job_family_page">
            <JobFamilyPageProduct />
          </IsAuthorisedFeature>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/role/sales">
          <IsAuthorisedFeature feature="job_family_page">
            <JobFamilyPageSales />
          </IsAuthorisedFeature>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/role/software-engineering">
          <IsAuthorisedFeature feature="job_family_page">
            <JobFamilyPageSoftwareEngineering />
          </IsAuthorisedFeature>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/oauth/slack">
          <SlackAuth />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/providers/:id">
          <Supplier />
        </AuthenticatedRoute>

        <AuthenticatedRoute path={`/internal-content/:id/`}>
          <IsAuthorised anyOf={[hasInternalLearning]}>
            <InternalResource />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path={`/explore/${orgPageUrl}/content/`}>
          <IsAuthorised anyOf={[hasInternalLearning]}>
            <InternalResources />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/explore">
          <Explore />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/topics/:id/playlists">
          <TopicPlaylists />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/topics/:id/trending">
          <TrendingTopic />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/topics/:subjectId/providers">
          <TopicSuppliers />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/topics/:id/:subtopicId">
          <Subtopic />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/topics/:id">
          <Topic />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/onboarding">
          <Onboarding />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/goals/:goalsOwnerId">
          <GrowthPlan />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/goals">
          <GrowthPlan />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/articles">
          <IsAuthorised anyOf={[hasResourceTypeView]}>
            <ResourceTypePage resourceType={"articles"} />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/books">
          <IsAuthorised anyOf={[hasResourceTypeView]}>
            <ResourceTypePage resourceType={"books"} />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/coaches">
          <IsAuthorised anyOf={[hasResourceTypeView]}>
            <ResourceTypePage resourceType={"coaches"} />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/conferences">
          <IsAuthorised anyOf={[hasResourceTypeView]}>
            <ResourceTypePage resourceType={"conferences"} />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/courses">
          <IsAuthorised anyOf={[hasResourceTypeView]}>
            <ResourceTypePage resourceType={"courses"} />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/meetups">
          <IsAuthorised anyOf={[hasResourceTypeView]}>
            <ResourceTypePage resourceType={"meetups"} />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/online-courses">
          <IsAuthorised anyOf={[hasResourceTypeView]}>
            <ResourceTypePage resourceType={"onlineCourses"} />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/other">
          <IsAuthorised anyOf={[hasResourceTypeView]}>
            <ResourceTypePage resourceType={"other"} />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/podcasts">
          <IsAuthorised anyOf={[hasResourceTypeView]}>
            <ResourceTypePage resourceType={"podcasts"} />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/videos">
          <IsAuthorised anyOf={[hasResourceTypeView]}>
            <ResourceTypePage resourceType={"videos"} />
          </IsAuthorised>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/skills/:skillId">
          <Skill />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/labs/roi">
          <IsAuthorised anyOf={[isOrgAdmin]}>
            <LabsROIPage />
          </IsAuthorised>
        </AuthenticatedRoute>

        <Route path="/login">
          {currentUser ? <Redirect to="/" /> : <LoginPage />}
        </Route>

        <Route path="/logout">
          {currentUser ? <Logout /> : <Redirect to="/login" />}
        </Route>

        <Route path="/auth-callback">
          <AuthCallback />
        </Route>

        <Route path="/auth-init">
          <AuthInit />
        </Route>

        <Route path="/magic-link">
          {currentUser ? <Redirect to="/" /> : <MagicLink />}
        </Route>

        <Route path="/forgotten-password">
          <ForgottenPassword />
        </Route>

        <Route path="/reset-password">
          {useFeatureIsOn("brute_force_development") ? (
            <ResetPassword />
          ) : (
            <ResetPasswordLegacy />
          )}
        </Route>

        <Route path="/invitation/">
          <Invitation />
        </Route>

        <Route
          path="/privacy-policy"
          render={() =>
            (window.location.href = "https://learnerbly.trustkeith.co/")
          }
        />

        <Route
          path="/terms"
          render={() =>
            (window.location.href =
              "https://www.learnerbly.com/terms-and-conditions")
          }
        />

        <Route
          path="/partner-terms"
          render={() =>
            (window.location.href = "https://www.learnerbly.com/partner-terms")
          }
        />

        <AuthenticatedRoute path="/*">
          <NotFoundPage />
        </AuthenticatedRoute>
      </Switch>
    </Suspense>
  );
};

export default withErrorBoundary(Routes);

import { Badge } from "@learnerbly/web-components";
import { faBolt, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import classnames from "classnames";
import noop from "lodash/noop";
import partial from "lodash/partial";
import { AppContext } from "../../../app-context";
import { formatCurrencyValue } from "src/shared/util/text-display";
import EventDetails from "src/shared/components/event-details";
import { DiscountedPriceText } from "src/shared/components/discounted-price-text";
import PropTypes from "prop-types";
import Tooltip from "../tooltip";

import styles from "./styles.scss";

const ProductItem = (props) => {
  const {
    selectHandler,
    autoApproved,
    isSelected,
    id,
    type,
    description,
    localisedPrice,
    supplier: resourceSupplier,
    resourceRequestSupplierId,
    remainingSpotCount,
    isForRegister,
    isInternalEvent,
    isVirtualCardRequest,
    format,
  } = props;

  const { currentUser } = useContext(AppContext);
  const supplier = format?.supplier || resourceSupplier;

  const availability = (remainingSpotCount) => {
    if (remainingSpotCount === null) {
      return null;
    }

    if (remainingSpotCount <= 0) {
      return "Fully booked!";
    }

    if (remainingSpotCount === 1) {
      return "1 spot available";
    }

    return `${remainingSpotCount} spots available`;
  };

  return (
    <div
      onClick={partial(selectHandler, id)}
      className={classnames(
        styles.container,
        {
          [styles.selected]: isSelected && remainingSpotCount !== 0,
        },
        {
          [styles.unavailable]: remainingSpotCount <= 0 && !isForRegister,
        },
        {
          [styles.virtualCard]: isVirtualCardRequest,
        },
      )}
      tabIndex={0}
    >
      {autoApproved && (
        <div className={styles.badge}>
          <Badge icon={faBolt}>Auto-Approved</Badge>
        </div>
      )}
      <span
        className={classnames(styles.description, {
          [styles.noPriceOrSpots]: isForRegister,
        })}
      >
        {description}
        {type === "book" && supplier?.name && ` by ${supplier.name}`}
      </span>
      <span className={styles.price}>
        {isInternalEvent && !isForRegister && availability(remainingSpotCount)}
        {!isInternalEvent && localisedPrice && (
          <DiscountedPriceText
            discountedPrice={localisedPrice.amount}
            supplierId={supplier?.id || resourceRequestSupplierId}
          />
        )}
        {!isInternalEvent &&
          localisedPrice &&
          formatCurrencyValue(
            localisedPrice.amount,
            localisedPrice.currency,
            currentUser.locale,
          )}
      </span>
      {(type === "event" || type === "internalEvent") && (
        <div className={styles.eventDetails}>
          <EventDetails {...props} />
        </div>
      )}

      {isVirtualCardRequest && (
        <div className={styles.virtualCardPrompt}>
          Purchase this yourself with a virtual card
          <Tooltip content="Buy directly from your preferred supplier using a virtual card we'll create for you">
            <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

ProductItem.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  localisedPrice: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }),
  type: PropTypes.string.isRequired,
  selectHandler: PropTypes.func,
  isSelected: PropTypes.bool,
  autoApproved: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  moreInfo: PropTypes.string,
  location: PropTypes.shape({
    city: PropTypes.string,
  }),
  resourceRequestSupplierId: PropTypes.string,
  supplier: PropTypes.shape({
    name: PropTypes.string,
  }),
  remainingSpotCount: PropTypes.number,
  isForRegister: PropTypes.bool,
  isInternalEvent: PropTypes.bool,
  isVirtualCardRequest: PropTypes.bool,
  format: PropTypes.shape({
    __typename: PropTypes.string,
    type: PropTypes.string,
    supplier: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
};

ProductItem.defaultProps = {
  selectHandler: noop,
  isVirtualCardRequest: false,
};

export default ProductItem;

import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import { FunctionComponent, useContext, useState } from "react";
import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import { Button, Modal } from "@learnerbly/web-components";

import ResourceRequest from "src/resource/components/resource-request";
import { SeeMore } from "../see-more/see-more";

import { AppContext } from "src/app-context";

import { track } from "src/shared/util/segment";

import { resource_resource } from "../../../resource/__generated__/resource";

import { RESOURCE_CARD_REQUEST_BUTTON_QUERY } from "./resource-card-request-button-query.graphql";

import styles from "./styles.scss";

type TResource = resource_resource & {
  image1x1: string;
  image2x1: string;
  image4x3: string;
};

type ResourceCardRequestButtonProps = {
  resourceId: string;
  resourceTitle: string;
};

const ResourceCardRequestButton: FunctionComponent<
  ResourceCardRequestButtonProps
> = ({ resourceId, resourceTitle }) => {
  const [showRequestPanel, setShowRequestPanel] = useState(false);
  const { currentUser } = useContext(AppContext);

  const trackClick = (
    resourceTitle: string | undefined,
    resourceId: string | undefined,
  ) => {
    track("Resource Card Request Button", {
      resourceTitle,
      resourceId,
    });
  };

  const { loading, error, data } = useQuery<{ resource: TResource }>(
    RESOURCE_CARD_REQUEST_BUTTON_QUERY,
    {
      variables: {
        id: resourceId,
        currency: currentUser.currency,
        contentRegion: currentUser.country,
      },
      skip: !showRequestPanel,
    },
  );
  const resource = data?.resource;

  if (error) {
    return null;
  }

  return (
    <>
      <div
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        {showRequestPanel &&
          !loading &&
          createPortal(
            <Modal
              heading={"Make a request"}
              onClose={() => setShowRequestPanel(false)}
              invalid={false}
            >
              <div className={styles.header}>
                <img
                  className={styles.image}
                  src={
                    resource?.image3x4 ||
                    resource?.image1x1 ||
                    resource?.image4x3 ||
                    resource?.image2x1 ||
                    resource?.image ||
                    ""
                  }
                />
                <span className={styles.resourceTitle}>{resource?.title}</span>
              </div>
              <div className={styles.content}>
                <p className={styles.summary}>{resource?.summary}</p>
                <div>
                  <SeeMore
                    isResourceDescription
                    text={resource?.description || ""}
                    heightInRem={4}
                  />
                </div>
              </div>
              <ResourceRequest
                supplierId={resource?.supplier?.id || ""}
                resourceId={resource?.id || ""}
                products={resource?.products || []}
                additionalTrackingContext={null}
              />
              <Link
                to={`/resources/${resource?.id}`}
                className={styles.viewFullLink}
              >
                View full page
              </Link>
            </Modal>,
            document.body,
          )}
      </div>
      <span
        className={styles.resourceCardRequestButton}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <Button
          loading={loading}
          small
          onClick={(e) => {
            e.preventDefault();
            trackClick(resourceTitle, resourceId);
            setShowRequestPanel(true);
          }}
        >
          <div className={styles.buttonContent}>
            Get
            <FontAwesomeIcon
              className={styles.buttonIcon}
              size="xs"
              icon={faPaperPlane}
            />
          </div>
        </Button>
      </span>
    </>
  );
};
export { ResourceCardRequestButton };

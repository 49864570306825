import { useFeatureValue } from "@growthbook/growthbook-react";
import { FunctionComponent, useContext, useMemo } from "react";
import { AppContext } from "src/app-context";

import { featureFlagNames } from "../../util/feature-flags";
import { formatCurrencyValue } from "../../util/text-display";

import styles from "./styles.scss";

type DiscountedPriceTextProps = {
  discountedPrice?: number;
  supplierId: string;
  showPercent?: boolean;
};

export const DiscountedPriceText: FunctionComponent<
  DiscountedPriceTextProps
> = ({ discountedPrice, supplierId, showPercent }) => {
  const { currentUser } = useContext(AppContext);

  const weeklyDeal = useFeatureValue<{
    resourceId: string;
    supplierId: string;
    deal: number;
  }>(featureFlagNames.jumbotronWeeklyDeal, {
    resourceId: "",
    supplierId: "",
    deal: 0,
  });

  const deal = useMemo(() => {
    return {
      originalPrice: formatCurrencyValue(
        (discountedPrice ?? 0) / (1 - weeklyDeal.deal),
        currentUser.currency,
        currentUser.locale,
      ),
      dealPercentage: `(${weeklyDeal.deal * 100 * -1}%)`,
    };
  }, [
    currentUser.currency,
    currentUser.locale,
    discountedPrice,
    weeklyDeal.deal,
  ]);

  if (weeklyDeal.supplierId === supplierId && deal.originalPrice) {
    return (
      <>
        {showPercent && (
          <span className={styles.dealPercentage}>{deal.dealPercentage}</span>
        )}
        <span className={styles.discountedPrice}>{deal.originalPrice}</span>
      </>
    );
  }
  return <></>;
};

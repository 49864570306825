import { FunctionComponent } from "react";
import { faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { differenceInWeeks } from "date-fns";

import { BudgetData } from "../../../../hooks/use-budget-data";

import { ActivityTicker } from "src/shared/components/activity-ticker/activity-ticker";

import { featureFlagNames } from "src/shared/util/feature-flags";

import styles from "./styles.scss";

export const BudgetReminderBanner: FunctionComponent<{
  budgetData: BudgetData;
}> = ({ budgetData }) => {
  const activityTickerActive = useFeatureIsOn(featureFlagNames.activityTicker);
  const remainingWeeks = differenceInWeeks(budgetData.endDate, new Date());

  return (
    <div
      className={styles.budgetReminderBanner}
      data-testid="budget-reminder-banner"
    >
      <div className={styles.inner}>
        <FontAwesomeIcon size="lg" icon={faHourglassHalf} />
        <p>
          Time’s ticking – make the most of your budget before it resets in{" "}
          <strong>
            {remainingWeeks} week{remainingWeeks === 1 ? "" : "s"}
          </strong>
          !
        </p>
        {activityTickerActive && (
          <div className={styles.activityTickerContainer}>
            <ActivityTicker />
          </div>
        )}
      </div>
    </div>
  );
};

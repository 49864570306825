import { graphql } from "src/__generated__";

export const GET_RECENT_ACTIVITY = graphql(`
  query recentActivity {
    recentActivity(size: 7) {
      resource {
        ... on Resource {
          id
          title
          type {
            id
            name
          }
        }
        ... on AdhocInfo {
          title
          link
        }
      }
      createdBy {
        id
        firstName
        lastName
        image
      }
      action
      createdAt
    }
  }
`);

import { FunctionComponent } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faClock,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";

import styles from "./styles.scss";

import { getLocalizedDateFields } from "../../util/text-display";

interface Props {
  startDate: string;
  endDate?: string;
  timezone: string;
  moreInfo?: string;
  location?: {
    city?: string | null;
  };
}

const EventDetails: FunctionComponent<Props> = ({
  startDate,
  endDate,
  location,
  timezone,
  moreInfo,
}) => {
  const userTimezone = moment.tz.guess();

  const userDateFields = getLocalizedDateFields(
    startDate,
    endDate,
    userTimezone,
  );
  const eventDateFields = getLocalizedDateFields(
    startDate,
    endDate,
    timezone,
    true,
  );

  const showLocalizedEventTimes = timezone !== userTimezone;
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <FontAwesomeIcon icon={faCalendarAlt} className={styles.icon} />
        <div>
          {showLocalizedEventTimes
            ? `${eventDateFields.date} (${userDateFields.date})`
            : userDateFields.date}
        </div>
      </div>

      <div className={styles.info}>
        <FontAwesomeIcon icon={faClock} className={styles.icon} />
        <div>
          {showLocalizedEventTimes
            ? `${eventDateFields.time} (${userDateFields.time})`
            : userDateFields.time}
        </div>
      </div>

      {location && (
        <div className={styles.info}>
          <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icon} />
          <div className={styles.location}>{location.city}</div>
        </div>
      )}

      {moreInfo && <p className={styles.moreInfo}>{moreInfo}</p>}
    </div>
  );
};

EventDetails.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  timezone: PropTypes.string.isRequired,
  moreInfo: PropTypes.string,
  location: PropTypes.shape({
    city: PropTypes.string,
  }),
};

export default EventDetails;

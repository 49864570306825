import { FunctionComponent } from "react";
import { Button } from "@learnerbly/web-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { useAppContext } from "../../../../../app-context";
import { UserForConstraints } from "../../../../util/user-constraints";
import { track } from "../../../../util/segment";

import styles from "./styles.scss";

export const LabsROIBanner: FunctionComponent = () => {
  const { currentUser } = useAppContext() as {
    currentUser?: UserForConstraints;
  };

  if (!currentUser || !currentUser.organisation) {
    return null;
  }

  return (
    <Link to="/labs/roi" onClick={() => track("Labs ROI banner clicked")}>
      <div data-testid="labs-roi-banner" className={styles.labsROIBanner}>
        <div className={styles.inner}>
          <main>
            <span className={styles.label}>NEW</span>
            <div className={styles.text}>
              <h3>
                <FontAwesomeIcon icon={faChartLine} /> Measure the ROI of
                learning at {currentUser.organisation.name}
              </h3>
              <p>
                Discover how Learnerbly is driving impact across your
                organisation with tailored insights into learning ROI
              </p>
            </div>
          </main>

          <aside>
            <Button secondary>
              Discover ROI insights <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </aside>
        </div>
      </div>
    </Link>
  );
};

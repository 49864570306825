// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uhdUOejyCmwnRivEU7iV{margin:0 0 20px 0}.uhdUOejyCmwnRivEU7iV p{margin:0 0 1rem}.uhdUOejyCmwnRivEU7iV ul{list-style-position:outside;margin:0 0 1rem 0}.uhdUOejyCmwnRivEU7iV li{margin:0 0 4px 1rem}\n", "",{"version":3,"sources":["webpack://./src/resource/components/resource-description/styles.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAkB,CADpB,wBAII,eAAgB,CAJpB,yBAQI,2BAA4B,CAC5B,iBAAkB,CATtB,yBAaI,mBAAoB","sourcesContent":[".container {\n  margin: 0 0 20px 0;\n\n  p {\n    margin: 0 0 1rem;\n  }\n\n  ul {\n    list-style-position: outside;\n    margin: 0 0 1rem 0;\n  }\n\n  li {\n    margin: 0 0 4px 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "uhdUOejyCmwnRivEU7iV"
};
export default ___CSS_LOADER_EXPORT___;

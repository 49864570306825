import { Button, Modal } from "@learnerbly/web-components";
import { useState, useContext, FunctionComponent } from "react";
import classnames from "classnames";

import { formatCurrencyValue } from "src/shared/util/text-display";
import { useHistory } from "react-router-dom";
import ProductItem from "src/shared/components/product-item";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import { AppContext } from "src/app-context";
import {
  hasRequestByVirtualCard,
  hasVirtualCardOnlyProducts,
} from "src/shared/util/user-constraints";
import {
  AdditionalTrackingContext,
  encodeTrackingContext,
} from "src/AlgoliaSearch/tracking-context";

import { DiscountedPriceText } from "src/shared/components/discounted-price-text";

import {
  resource_resource_products as TProduct,
  resource_resource as TResource,
} from "../../__generated__/resource";

import styles from "./styles.scss";

const ResourceRequest: FunctionComponent<{
  resourceId: string;
  supplierId: string;
  products: Array<TProduct>;
  cheapestProduct?: TResource["cheapestProduct"];
  additionalTrackingContext: AdditionalTrackingContext | null;
}> = ({
  resourceId,
  supplierId,
  products,
  cheapestProduct,
  additionalTrackingContext,
}) => {
  const [selectedProduct, setSelectedProduct] = useState<TProduct | null>(null);
  const [showModal, setShowModal] = useState(false);
  const { currentUser } = useContext(AppContext);
  const history = useHistory();

  const hasPotsEnabled = useFeatureIsOn("pots");

  const hasVirtualCardFeature =
    hasRequestByVirtualCard(currentUser) ||
    hasVirtualCardOnlyProducts(currentUser);

  // If there is no price, it's an off-platform product
  const isVirtualCardRequest = selectedProduct
    ? !selectedProduct.isFulfilmentManaged && hasVirtualCardFeature
    : false;

  const submitRequestHandler = () => {
    const trackingContext = encodeTrackingContext(additionalTrackingContext);

    if (isVirtualCardRequest) {
      history.push(
        `/request/off-platform/${resourceId}/${selectedProduct?.id}/`,
      );
    } else {
      history.push(
        `/request/${resourceId}/${
          selectedProduct?.id || ""
        }/${trackingContext}`,
      );
    }
  };

  const numberOfProducts = products.length;

  const value =
    cheapestProduct && cheapestProduct.localisedPrice
      ? formatCurrencyValue(
          cheapestProduct?.localisedPrice.amount,
          cheapestProduct?.localisedPrice.currency,
          currentUser.locale,
        )
      : null;

  const shouldShowRequestButton =
    (hasPotsEnabled && currentUser.pots.length !== 0) || !hasPotsEnabled;

  return (
    <section
      className={classnames(styles.container, {
        [styles.virtualCardSelected]: isVirtualCardRequest,
      })}
    >
      <div className={styles.pricing}>
        {numberOfProducts === 1 && (
          <DiscountedPriceText
            discountedPrice={cheapestProduct?.localisedPrice?.amount}
            supplierId={supplierId}
          />
        )}
        {numberOfProducts > 1 && value ? `From ${value}` : value}
      </div>
      <div className={styles.action}>
        {shouldShowRequestButton && (
          <Button
            icon={faPaperPlane}
            stretch
            onClick={() => setShowModal(true)}
          >
            Request
          </Button>
        )}
        {showModal && (
          <Modal
            heading="Choose an option"
            nextText="Choose this option…"
            onNext={submitRequestHandler}
            onClose={() => setShowModal(false)}
            showCancel
            invalid={!selectedProduct?.id}
          >
            <ul className={styles.products}>
              {products.map((product) => (
                <li key={product.id} className={styles.product}>
                  <ProductItem
                    {...product}
                    resourceRequestSupplierId={supplierId}
                    selectHandler={() => setSelectedProduct(product)}
                    isSelected={selectedProduct?.id === product.id}
                    isVirtualCardRequest={
                      !product.isFulfilmentManaged && hasVirtualCardFeature
                    }
                  />
                </li>
              ))}
            </ul>
          </Modal>
        )}
      </div>
    </section>
  );
};

export default ResourceRequest;

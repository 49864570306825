import { FunctionComponent, useContext } from "react";
import classNames from "classnames";

import { AppContext } from "../../../app-context";

import styles from "./styles.scss";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useRouteMatch } from "react-router-dom";
import { featureFlagNames } from "../../util/feature-flags";
import { isOrgAdmin } from "../../util/user-constraints";

export const Navigation: FunctionComponent = () => {
  const { currentUser, showNavigation } = useContext(AppContext);

  const labsROIBannerValue = useFeatureIsOn(featureFlagNames.labsROIBanner);
  const labsROIPathMatch = useRouteMatch("/labs/roi");

  const shouldShowLabsROIBanner =
    !!labsROIBannerValue && !labsROIPathMatch && isOrgAdmin(currentUser);

  if (!currentUser || !showNavigation) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.spacer,
        styles.homepagePhase1,
        shouldShowLabsROIBanner && styles.labsROIBanner,
      )}
    />
  );
};

export default Navigation;

import { FunctionComponent, useState } from "react";

import styles from "./styles.scss";

import ResourceDescription from "src/resource/components/resource-description/resource-description";

interface SeeMoreProps {
  text: string;
  heightInRem?: number;
  isResourceDescription?: boolean;
}

export const SeeMore: FunctionComponent<SeeMoreProps> = ({
  text,
  heightInRem,
  isResourceDescription,
}) => {
  const [showText, setShowText] = useState(false);

  let textheight = "2rem";

  if (heightInRem && !showText) {
    textheight = `${heightInRem * 1.5}rem`;
  }

  return (
    <>
      <span
        data-testid="see-more"
        className={showText ? styles.showContent : styles.hideContent}
        style={{ height: textheight }}
      >
        {isResourceDescription ? (
          <ResourceDescription description={text} />
        ) : (
          text
        )}
      </span>
      <span style={{ display: showText ? "none" : "block" }}>...</span>
      <p>
        <button
          className={styles.readMoreLessButton}
          onClick={() => setShowText(!showText)}
        >
          {showText ? " Read less" : "Read more"}
        </button>
      </p>
    </>
  );
};

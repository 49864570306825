import { graphql } from "src/__generated__";

export const RESOURCE_CARD_REQUEST_BUTTON_QUERY = graphql(`
  query resource(
    $id: ID!
    $currency: CurrencyCode!
    $contentRegion: CountryCode!
  ) {
    resource(id: $id) {
      id
      active
      title
      summary
      description
      cheapestProduct(filter: { by_country: $contentRegion }) {
        id
        localisedPrice(currency: $currency) {
          currency
          amount
        }
      }
      image
      image1x1
      image4x3
      image2x1
      image3x4
      isFree
      supplier {
        id
      }
      products(filter: { by_country: $contentRegion }) {
        id
        localisedPrice(currency: $currency) {
          currency
          amount
        }
        description
        active
        type
        autoApproved
        isFulfilmentManaged
        ... on Event {
          startDate
          endDate
          moreInfo
          timezone
          location {
            city
            country
            street
            postcode
          }
          link
        }
        ... on InternalEvent {
          id
          endDate
          startDate
          attending
          timezone
          location {
            city
          }
          remainingSpotCount
          link
        }
        ... on Book {
          id
          format {
            type
            supplier {
              id
              name
            }
          }
        }
        link
      }
    }
  }
`);

import classnames from "classnames";
import { useRef, useState } from "react";
import Popover from "../popover";

import styles from "./styles.scss";

interface Props {
  children: React.ReactNode;
  content: string;
  offset?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Tooltip = ({ children, content, offset = 3 }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const childRef = useRef<HTMLDivElement>(null);

  const left = childRef.current?.getBoundingClientRect().left ?? 0;
  const top = childRef.current?.getBoundingClientRect().top ?? 0;
  const width = childRef.current?.getBoundingClientRect().width ?? 0;

  const mouseOver = () => setIsOpen(true);
  const mouseOut = () => setIsOpen(false);

  return (
    <div onMouseEnter={mouseOver} onMouseLeave={mouseOut} ref={childRef}>
      {children}
      <Popover
        className={classnames(styles.tooltip, { [styles.open]: isOpen })}
        left={left + width / 2}
        top={top - offset}
      >
        {content}
      </Popover>
    </div>
  );
};

export default Tooltip;

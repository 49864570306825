import { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import classNames from "classnames";

import {
  faBookmark,
  faEllipsisH,
  faBan,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faBookmarkRegular } from "@fortawesome/free-regular-svg-icons";

import {
  ActionList,
  IconButton,
  Text,
  ThemeProvider,
} from "@learnerbly/web-components";

import LinkLabel from "src/shared/components/link-label";
import { ResourceCardRequestButton } from "../resource-card-request-button/resource-card-request-button";

import { formatText, formatCurrencyValue } from "src/shared/util/text-display";
import { featureFlagNames } from "src/shared/util/feature-flags";
import { track } from "src/shared/util/segment";

import { AppContext } from "src/app-context";

import toggleResourceBookmarkMutation from "./toggle-resource-bookmark.graphql";
import addResourceFeedbackMutation from "./add-resource-feedback.graphql";
import { homePageQuery } from "src/home/home.ts.graphql";

import { encodeTrackingContext } from "src/AlgoliaSearch/tracking-context";

import styles from "./styles.scss";

const ResourceCard = ({
  resource,
  trackContext,
  trackPosition,
  enableFeedback = false,
  enableBookmark = true,
  highlight = false,
  additionalTrackingContext = {},
  callbacks = {},
}) => {
  const homepageOptimisationPhase1 = useFeatureIsOn(
    featureFlagNames.homepageOptimisationPhase1,
  );
  const resourceCardRequestButtonActive = useFeatureIsOn(
    featureFlagNames.resourceCardRequestButton,
  );

  const [hasGivenFeedback, setHasGivenFeedback] = useState(false);

  const { currentUser } = useContext(AppContext);
  const image =
    resource.image1x1 ||
    resource.image4x3 ||
    resource.image ||
    resource.image2x1;
  const { cheapestProduct } = resource;

  const urlTrackingContext = useMemo(() => {
    return encodeTrackingContext(additionalTrackingContext);
  }, [additionalTrackingContext]);

  const [toggleResourceBookmark] = useMutation(toggleResourceBookmarkMutation, {
    variables: {
      id: resource.id,
    },
    optimisticResponse: {
      __typename: "Mutation",
      toggleResourceBookmark: {
        id: resource.id,
        __typename: "Resource",
        bookmarked: !resource.bookmarked,
      },
    },
    refetchQueries: [
      {
        query: homePageQuery,
        variables: {
          contentRegion: currentUser.country,
          currency: currentUser.currency,
        },
      },
    ],
  });

  const [addResourceFeedback] = useMutation(addResourceFeedbackMutation);

  const handleResourceFeedback = async (reason) => {
    await addResourceFeedback({
      variables: { resourceId: resource.id, action: "HIDE", reason },
      refetchQueries: ["userRecommendedResources"],
    });

    setHasGivenFeedback(true);

    track("Submitted User Resource Feedback", {
      resourceId: resource.id,
      resourceType: resource.type.name,
      resourceTitle: resource.title,
      context: trackContext,
      action: "HIDE",
      reason,
      featureToggles: additionalTrackingContext?.featureToggles,
    });
  };

  const cheapestPrice =
    cheapestProduct &&
    cheapestProduct.localisedPrice &&
    formatCurrencyValue(
      cheapestProduct.localisedPrice.amount,
      cheapestProduct.localisedPrice.currency,
      currentUser.locale,
    );

  const trackClick = (event) => {
    if (event.defaultPrevented) {
      return;
    }

    const getAdditionalTrackingContext = (additionalTrackingContext) => {
      let additionalTrackingProperties = {
        index: `${process.env.STAGE}_resources`,
        objectID: resource.id,
        eventType: "click",
      };

      if (additionalTrackingContext) {
        additionalTrackingProperties = {
          ...additionalTrackingProperties,
          ...additionalTrackingContext,
        };
      }

      return additionalTrackingProperties;
    };

    track("Product Clicked", {
      product_id: resource.id,
      sku: resource.id,
      name: resource.title,
      image_url: image,
      context: trackContext,
      position: trackPosition,
      highlight,
      category: resource.type.name,
      supplier: resource.supplier && resource.supplier.name,
      ...(cheapestProduct && { price: cheapestProduct.price / 100 }),
      ...(additionalTrackingContext &&
        getAdditionalTrackingContext(additionalTrackingContext)),
    });
  };

  const showRequestButton = (resourceItem) => {
    if (resourceItem.active === false || resourceItem.isFree) {
      return false;
    }

    if (cheapestPrice || resourceItem.products?.length > 0) {
      return true;
    }

    return false;
  };

  return (
    <Link
      className={classNames(
        styles.resource,
        hasGivenFeedback && styles.withFeedback,
        homepageOptimisationPhase1 && styles.homepagePhase1,
        highlight && styles.highlight,
      )}
      data-testid="resource-card"
      to={`/resources/${resource.id}/${urlTrackingContext}`}
      onClick={trackClick}
    >
      <div className={styles.header}>
        <ThemeProvider
          theme={{
            colors: {
              secondaryButton: "rgba(23, 17, 36, 0.7)",
              secondaryButtonHover: "#171124",
              text: "#f7f9fb",
            },
          }}
        >
          <div className={styles.actions}>
            {enableFeedback ? (
              <ActionList
                buttonType="icon"
                buttonProps={{
                  icon: faEllipsisH,
                  aria: { label: "More options" },
                }}
                listItems={[
                  {
                    icon: faBan,
                    text: "I’ve already read/watched/attended this",
                    onClick: () => handleResourceFeedback("CONSUMED"),
                  },
                  {
                    icon: faBan,
                    text: "This isn’t relevant to me",
                    onClick: () => handleResourceFeedback("RELEVANCE"),
                  },
                ]}
              />
            ) : null}

            {enableBookmark && (
              <IconButton
                icon={
                  resource.bookmarked === undefined
                    ? faSpinner
                    : resource.bookmarked
                    ? faBookmark
                    : faBookmarkRegular
                }
                aria={{
                  label: "Bookmark",
                }}
                onClick={(event) => {
                  event.preventDefault();

                  if (callbacks.toggleResourceBookmark) {
                    callbacks.toggleResourceBookmark(resource.id);
                  }

                  toggleResourceBookmark().then(
                    ({
                      data: {
                        toggleResourceBookmark: { id, bookmarked },
                      },
                    }) =>
                      track(
                        bookmarked
                          ? "Resource Bookmarked"
                          : "Resource Unbookmarked",
                        {
                          resourceId: id,
                          resourceType: resource.type.name,
                          resourceTitle: resource.title,
                          context: trackContext,
                          featureToggles:
                            additionalTrackingContext?.featureToggles,
                          index: `${process.env.STAGE}_resources`,
                          objectID: id,
                          eventType: bookmarked ? "conversion" : undefined,
                        },
                      ),
                  );
                }}
                pulse={resource.bookmarked === undefined}
              />
            )}
          </div>
        </ThemeProvider>
        {image ? (
          <div className={styles.imageContainer}>
            <img className={styles.image} src={image} alt="" />
          </div>
        ) : (
          <div className={styles.imagePlaceholder} />
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.label}>
          <LinkLabel type={resource.type.name} />
        </div>

        <div className={styles.heading}>
          <Text textElement="h4" textVariant="bold">
            {formatText(resource.title, 100)}
          </Text>
        </div>
        <div className={styles.footer}>
          {cheapestPrice && (
            <div className={styles.price} data-testid="resource-price">
              <i>from</i> {cheapestPrice}
            </div>
          )}
          <div>{resource.supplier && resource.supplier.name}</div>
        </div>
        {resourceCardRequestButtonActive && showRequestButton(resource) && (
          <ResourceCardRequestButton
            resourceId={resource.id}
            resourceTitle={resource.title}
          />
        )}
      </div>
    </Link>
  );
};

ResourceCard.propTypes = {
  resource: PropTypes.object.isRequired,
  large: PropTypes.bool,
  trackContext: PropTypes.string,
  trackPosition: PropTypes.number,
  enableFeedback: PropTypes.bool,
  enableBookmark: PropTypes.bool,
  highlight: PropTypes.bool,
  additionalTrackingContext: PropTypes.shape({
    queryID: PropTypes.string,
    featureToggles: PropTypes.shape(),
  }),
  callbacks: PropTypes.shape({ toggleResourceBookmark: PropTypes.func }),
};

export default ResourceCard;

import { useState, useEffect } from "react";
import { createPortal } from "react-dom";

interface Props {
  children: React.ReactNode;
  element?: string;
  className?: string;
  left?: number;
  top?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Popover = ({
  left = 0,
  top = 0,
  children,
  className,
  element = "div",
}: Props) => {
  const [container] = useState(() => {
    const el = document.createElement(element);
    if (className) {
      el.classList.add(...className.split(" "));
    }
    return el;
  });

  useEffect(() => {
    if (className) {
      container.classList.remove(...container.classList);
      container.classList.add(...className.split(" "));
    }
  }, [className, container]);

  const { height, width } = container.getBoundingClientRect();

  container.style.left = `${left - width / 2}px`;
  container.style.top = `${top - height}px`;

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return createPortal(children, container);
};

export default Popover;

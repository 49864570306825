import PropTypes from "prop-types";
import Markdown from "react-markdown";

import styles from "./styles.scss";

export const ResourceDescription = ({ description }) => (
  <div data-testid="resource-description" className={styles.container}>
    <Markdown>{description}</Markdown>
  </div>
);

ResourceDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default ResourceDescription;

import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import NewsTicker from "./vertical-ticker";
import Loading from "../loading";
import Tooltip from "../tooltip";

import { track } from "src/shared/util/segment";

import { GET_RECENT_ACTIVITY } from "./activity-ticker.graphql";

import styles from "./styles.scss";

const handleClick = (title: string, id: string) =>
  track("Activity Ticker", {
    title,
    id,
  });

const ActivityTicker: FunctionComponent = () => {
  const { data, error, loading } = useQuery(GET_RECENT_ACTIVITY, {
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    return <Loading heightMode="shrink" />;
  }

  if (error) {
    return null;
  }

  if (!data) {
    return null;
  }

  const { recentActivity } = data;

  if (!recentActivity || recentActivity.length < 1) {
    return null;
  }

  return (
    <div className={styles.activityTicker}>
      <span className={styles.title}>Recent activity:</span>
      <NewsTicker
        maxRows={1}
        speed={900}
        duration={7000}
        autoStart={true}
        pauseOnHover={false}
        className={styles.newsTicker}
      >
        {recentActivity.map((item) => {
          const resourceTitle = item.resource.title;
          const resourceId =
            item.resource.__typename === "Resource" ? item.resource.id : "";

          return (
            <Link
              key={resourceTitle}
              className={styles.link}
              to={`/resources/${resourceId}`}
              onClick={() => handleClick(resourceTitle, resourceId)}
            >
              <Tooltip content={resourceTitle}>
                <span className={styles.name}>{item.createdBy?.firstName}</span>
                <span>requested</span>
                <span className={styles.resourceName}>{resourceTitle}</span>
              </Tooltip>
            </Link>
          );
        })}
      </NewsTicker>
    </div>
  );
};

export { ActivityTicker };
